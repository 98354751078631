@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
:root {
  --primary-white: #FFFFFF;
  --primary-light-grey: #F4F4F4;
  --primary-grey: #CCD1D9;
  --primary-dark-grey: #666666;
  --primary-blue: #340DF6;
  --primary-dark: #292B2C;
  --secondary-dark: rgb(52, 58, 64);
  --primary-light-blue: #2C8EF0;
}

body {
  font-family: "Roboto Condensed" sans-serif !important;
}

/************************************  NAVBAR  ******************************************/
nav {
  z-index: 2;
}

.logo {
  width: 3.75rem;
}

/************************************  NAVBAR LINKS  ******************************************/
nav a.nav-link {
  /* font-weight: 500; /*Adds weight to the links in the navbar*/
  letter-spacing: 0.038rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: #FFFFFF !important;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid #292B2C;
  border-top: 0.1875rem solid var(--primary-dark);
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
}

.navbar-nav > li > a.active,  .navbar-nav > li > a.active:focus {
  border-top: 0.1875rem solid #340DF6;
  border-top: 0.1875rem solid var(--primary-blue);
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid #340DF6;
  border-top: 0.1875rem solid var(--primary-blue);
}

.brand-icons > li {
  margin-right: 10rem;
}

/************************************  HEADER  ******************************************/
.header-wrapper {
  position: relative;
  background: url(/static/media/header-image.7c33b816.gif) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.headshot {
  z-index: 2;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.main-info h1 {
  color: #FFFFFF;
  color: var(--primary-white);
  padding-top: 5px;
  text-transform: uppercase;
}

/**************** HEADER TYPED TEXT ****************/
.typed-text {
  font-size: 1.5rem;
  color: #340DF6;
  color: var(--primary-blue);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 2;
}

@media(max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
}

/**************** HEADER MAIN OFFER BUTTON ****************/
.btn-main-offer {
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  margin: 2rem;
}

.btn-main-offer:hover {
  text-decoration: none !important;
  background-color: #FD7E14 !important;
  color: #FFFFFF !important;
  color: var(--primary-white) !important;
  transition: .2s ease-in-out !important;
}

/**************** JS PARTICLES ****************/
.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

/* This was causing issues with the particles displaying on mobile devices so it has been commented out for now
@media(max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}
*/

/************************************  ABOUT ME  ******************************************/
.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 16rem;
  padding: 0.3rem;
}

.aboutme-heading {
  color: #340DF6;
  color: var(--primary-blue);
  text-transform: uppercase;
}

@media(max-width: 468px) {
  .profile-img {
    width: 10rem;
  }
}

/************************************  SKILLS  ******************************************/
.skills {
  text-align: center;
  padding-bottom: 2rem;
  background: #F4F4F4;
  background: var(--primary-light-grey);
}

.skills h1 {
  color: rgb(52, 58, 64);
  color: var(--secondary-dark);
  text-transform: uppercase;
}

.skills .circle {
  position: relative;
  margin: 0.375rem auto;
  background: #340DF6;
  background: var(--primary-blue);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.skills .icon {
  font-size: 2rem;
  color: #FFFFFF;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.skills .box {
  height: 14rem;
  border-bottom: 0.625rem solid #340DF6;
  border-bottom: 0.625rem solid var(--primary-blue);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: #FFFFFF;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
}

.skills .box:hover {
  background: #340DF6;
  background: var(--primary-blue);
  border-bottom: 0.625rem solid rgb(52, 58, 64);
  border-bottom: 0.625rem solid var(--secondary-dark);
}

.skills .box:hover .icon {
  color: rgb(52, 58, 64);
  color: var(--secondary-dark);
}

.skills .box:hover .circle {
  background: #FFFFFF;
  background: var(--primary-white);
}

.skills .box:hover h3 {
  color: #FFFFFF;
  color: var(--primary-white);
}

.skills .box:hover p {
  color: #FFFFFF;
  color: var(--primary-white);
}

/************************************  EXPERIENCE  ******************************************/
.experience{
  padding: 0.7rem;
}

.experience-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}

.experience h1 {
  text-transform: uppercase;
  color: #340DF6;
  color: var(--primary-blue)
}

.experience-wrapper:before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: #CCD1D9;
  background: var(--primary-grey);
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.timeline-image {
  border: 3px solid #340DF6;
  border: 3px solid var(--primary-blue);
  max-width: 100%;
  max-height: 100%;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid rgb(52, 58, 64);
  border: 0.3125rem solid var(--secondary-dark);
  background: #340DF6;
  background: var(--primary-blue);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: #666666;
  color: var(--primary-dark-grey);
  direction: ltr;
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: #282C34;
  font-weight: 900;
}

/**************** MEDIA ****************/
@media(max-width: 768px) {
  .experience .container {
    padding-left: 0;
  }
  .experience-wrapper:before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .timeline-block-right {
    float: none;
    width: 100%;
  }
  .timeline-block-left {
    float: none;
    width: 100%;
    direction: ltr;
  }
}

/************************************  CONTACT  ******************************************/
.contact {
  background: rgb(52, 58, 64);
  background: var(--secondary-dark);
  padding: 3rem 0;
}

.contact h1 {
  color: #F0AD4E;
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contact p {
  color: #F4F4F4;
  color: var(--primary-light-grey);
  font-size: 1.2rem;
}

.contact-btn {
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  margin: 1rem 0 0 0;
  width: 40% !important;
}

.contact-btn:hover {
  text-decoration: none !important;
  background-color: #5BC0DE !important;
  color: #FFFFFF !important;
  color: var(--primary-white) !important;
  transition: .2s ease-in-out !important;
}

/***************************  CONTACT INPUTS  ***************************/
.contact input, .contact textarea {
  width: 100%;
  box-sizing: border-box;
  resize: none;
  background: none;
  border: 0;
  border-bottom: 0.125rem solid #666666;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: #F0AD4E;
  outline: 0 !important;
  box-shadow: none !important;
  top: 0;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contact .container {
  padding: 4rem 0;
}

.contact input:focus, .contact textarea:focus {
  background: transparent;
  color: #F0AD4E;
  border-bottom: 0.125rem solid #666666;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contact textarea {
  height: 12.2rem !important;
}

.line {
  position: relative;
  top: -0.83rem;
  width: 0;
  height: 0.125rem;
  background: #340DF6;
  display: inline-block;
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

/**************** MEDIA ****************/
@media(max-width: 768px) {
  .contact {
    overflow-x: hidden;
  }
  .contact .container {
    padding: 0 1rem;
  }
  .contact p {
    padding: 0 0.4rem;
  }
}

